import ProfilForm from "../../../components/form/ProfilForm";
import Container from "../../../components/ui/atoms/Container";

const RegisterStudent = () => {
    return <Container>
        <ProfilForm isCreation />
    </Container>
}


export default RegisterStudent;
