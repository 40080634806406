import tokens from "../tokens";

const fr = {
    [tokens.entities.collaborator.jobTitle]: "Poste occupé",
    [tokens.entities.company.name]: "Nom",
    [tokens.entities.company.legalStatus]: "Status juridique",
    [tokens.entities.company.siretNumber]: "Siret",
    [tokens.entities.company.socialLink]: "Url du site",
    [tokens.entities.company.city]: "Ville",
    [tokens.entities.company.postCode]: "Code postal",
    [tokens.entities.company.address]: "Adresse",
    [tokens.entities.company.phone]: "Téléphone",
    [tokens.entities.company.age.label]: "Date de création de l'entreprise",
    [tokens.entities.company.age.string.singular]: "1 an",
    [tokens.entities.company.age.string.plural]: "{{ age }} ans",
    [tokens.entities.company.age.string.less]: "Moins d'un an",
    [tokens.entities.company.effective]: "Effectif",
    [tokens.entities.company.turnover]: "Chiffre d'affaire (en M€)",
    [tokens.entities.company.presentation]: "Présentation",
    [tokens.entities.company.openingTime]: "Heure d'ouverture",
    [tokens.entities.company.twitterLink]: "Lien Twitter",
    [tokens.entities.company.linkedinLink]: "Lien LinkedIn",
    [tokens.entities.company.facebookLink]: "Lien Facebook",
    [tokens.entities.company.instagramLink]: "Lien Instagram",
    [tokens.entities.company.logo]: "Logo",
    [tokens.entities.company.logoIcon]: "Icone",
    [tokens.entities.company.category]: "Catégorie d'entreprise",
    [tokens.entities.company.additionalAddress]: "Complément d'adresse",
    [tokens.entities.company.activities]: "Secteurs d'activité",
    [tokens.entities.company.firstImage]: "1ère image",
    [tokens.entities.company.secondImage]: "2ème image",
    [tokens.entities.company.thirdImage]: "3ème image",
    [tokens.entities.company.fourthImage]: "4ème image",
    [tokens.entities.company.fifthImage]: "5ème image",
    [tokens.entities.offer.name]: "Nom",
    [tokens.entities.offer.createdAt.first]: "Date",
    [tokens.entities.offer.createdAt.second]: "Date publication",
    [tokens.entities.offer.availableAt]: "Date limite",
    [tokens.entities.offer.deletedAt]: "Status",
    [tokens.entities.offer.isInternship]: "Type",
    [tokens.roles.collaborator.masculine]: "Collaborateur",
    [tokens.roles.collaborator.feminine]: "Collaboratrice",
    [tokens.roles.student.masculine]: "Étudiant",
    [tokens.roles.student.feminine]: "Étudiante",
    [tokens.roles.sponsor]: "Sponsor",
    [tokens.roles.admin.masculine]: "Administrateur",
    [tokens.roles.admin.feminine]: "Administratrice",
    [tokens.apiCollectionList.title]: "Résultats",
    [tokens.apiCollectionList.sorting.label]: "Trier par :",
    [tokens.apiCollectionTable.filter.label]: "Filtrer par status :",
    [tokens.apiCollectionTable.itemsPerPage.label]: "Voir",
    [tokens.facets.label.companies.activities.name]: "Secteurs d'activité",
    [tokens.facets.label.companies.category.name]: "Catégorie",
    [tokens.facets.label.companies.effective]: "Effectif",
    [tokens.facets.label.companies.range.global]: "Distance - {{min}} à {{max}} km",
    [tokens.facets.label.companies.range.input]: "A moins de {{value}} km",
    [tokens.facets.label.offers.activities.name]: "Profils métiers",
    [tokens.facets.label.offers.studyLevel.name]: "Niveau recherché",
    [tokens.facets.label.offers.end]: "Durée",
    [tokens.facets.label.offers.range.global]: "Distance - {{min}} à {{max}} km",
    [tokens.facets.label.offers.range.input]: "A moins de {{value}} km",
    [tokens.facets.options.all]: "Tous",
    [tokens.facets.options.betweenAndMore]: "et supérieur",
    [tokens.filters.all]: "Tous",
    [tokens.input.file.infos]: "Aucun fichier séléctionné",
    [tokens.actions.login]: "Se connecter",
    [tokens.actions.myAccount]: "Mon compte",
    [tokens.actions.createAccount]: "Créer un compte",
    [tokens.actions.logout]: "Se déconnecter",
    [tokens.actions.back]: "Retour",
    [tokens.actions.add]: "Ajouter",
    [tokens.actions.update]: "Mettre à jour",
    [tokens.navbar.modal.title]: "Vous êtes",
    [tokens.navbar.modal.student]: "Un étudiant",
    [tokens.navbar.modal.company]: "Une entreprise",
    [tokens.navbar.links.home]: "Accueil",
    [tokens.navbar.links.offers]: "Offres",
    [tokens.navbar.links.requests]: "Demandes",
    [tokens.navbar.links.companies]: "Entreprises",
    [tokens.navbar.links.students]: "Etudiants",
    [tokens.navbar.links.internship]: "Offre de stage",
    [tokens.navbar.links.workStudy]: "Offre d'alternance",
    [tokens.navbar.links.UI]: "UI",
    [tokens.footer.description]: "Première plateforme dédiée à la recherche de stages et d’alternance qui relie automatiquement les étudiants et les entreprises.",
    [tokens.footer.links.home]: "Accueil",
    [tokens.footer.links.offers]: "Offres",
    [tokens.footer.links.students]: "Etudiants",
    [tokens.footer.links.companies]: "Entreprises",
    [tokens.footer.links.blog]: "Blog",
    [tokens.footer.links.sponsors]: "Sponsors",
    [tokens.footer.links.legal]: "Mentions légales",
    [tokens.footer.links.personalData]: "Données personnelles",
    [tokens.footer.links.contact]: "Contact",
    [tokens.footer.register.title]: "Etudiants, créez votre compte",
    [tokens.footer.register.description]: "Recevez automatiquement par email les offres qui vous intéressent !",
    [tokens.footer.register.email]: "Votre email",
    [tokens.footer.register.submit]: "C'est parti",
    [tokens.footer.copyright]: "2024 © Bourse aux Stages - Tous droits réservés",
    [tokens.sidebar.title]: "Mon compte",
    [tokens.sidebar.links.myCompany]: "Fiche entreprise",
    [tokens.sidebar.links.requests]: "Candidatures",
    [tokens.sidebar.links.offers]: "Offres",
    [tokens.sidebar.links.parameters]: "Paramètres",
    [tokens.page.companyDetails.activity]: "Activité",
    [tokens.page.companyDetails.age]: "Ancienneté",
    [tokens.page.companyDetails.effective]: "Effectif",
    [tokens.page.companyDetails.turnover]: "Chiffre d'affaires",
    [tokens.page.companyDetails.city]: "Situation",
    [tokens.page.companyDetails.presentation]: "Présentation",
    [tokens.page.companyDetails.socialsLinks]: "Réseaux sociaux",
    [tokens.page.companyDetails.contact]: "Nous joindre",
    [tokens.page.companyDetails.contacts]: "Vos contacts",
    [tokens.page.companyDetails.contactUs]: "Nous envoyer un message",
    [tokens.page.companyDetails.phone]: "Téléphone",
    [tokens.page.companyDetails.images.alt]: "Image de {{company}}",
    [tokens.page.companyDetails.relatedOffers.internship]: "Offres de stage proposées",
    [tokens.page.companyDetails.relatedOffers.workStudy]: "Offres en alternance proposées",
    [tokens.page.companies.title]: "Liste des entreprises",
    [tokens.page.companies.description]: "Découvrez les entreprises qui proposent des offres de stage ou d’alternance",
    [tokens.page.companies.apiCollectionList.foundLabel]: "entreprise(s) trouvée(s)",
    [tokens.page.offers.internship.title]: "Offres de stage",
    [tokens.page.offers.internship.apiCollectionList.foundLabel]: "offre(s) de stage trouvée(s)",
    [tokens.page.offers.workStudy.title]: "Offres d'alternance",
    [tokens.page.offers.workStudy.apiCollectionList.foundLabel]: "offre(s) d'alternance trouvée(s)",
    [tokens.page.home.hero.title.first]: "Trouver un stage n'aura jamais été",
    [tokens.page.home.hero.title.underlined]: "aussi facile !",
    [tokens.page.home.hero.description]: "Trouvez les offres de stage ou d’alternance près de chez vous qui correspondent à votre profil et à vos attentes.",
    [tokens.page.home.hero.offers]: "<secondary>{{internshipsNumber}}</secondary> offres de <bold>stages</bold> <bold>|</bold> <secondary>{{workStudiesNumber}}</secondary> offres <bold>d’alternance</bold> n’attendent que vous !",
    [tokens.page.home.highlightedCompanies.title]: "Entreprises à la une",
    [tokens.page.home.highlightedCompanies.image.alt]: "Logo de {{company}}",
    [tokens.page.home.incentive.title]: "Entreprises, déposez vos offres gratuitement",
    [tokens.page.home.incentive.text]: "Vous pourrez gérer votre planning d’accueil et bénéficier de nombreux services intégrés.",
    [tokens.page.home.incentive.cta]: "Créer votre compte",
    [tokens.page.home.lastOffers.title]: "Dernières <secondary>offres</secondary>",
    [tokens.page.home.lastOffers.cta]: "Toutes les offres",
    [tokens.page.home.lastRequests.title]: "Dernières <secondary>demandes</secondary>",
    [tokens.page.home.lastRequests.cta]: "Toutes les demandes",
    [tokens.page.login.hero.title.first]: "De retour ?",
    [tokens.page.login.hero.title.underlined]: "Connectez-vous",
    [tokens.page.login.form.email]: "Identifiant",
    [tokens.page.login.form.password]: "Mot de passe",
    [tokens.page.login.form.submit]: "Connexion",
    [tokens.page.login.notifications.error]: "Email ou mot de passe incorrect",
    [tokens.page.login.notifications.inactive]: "Votre compte est actuellement désactivé",
    [tokens.page.register.titleCompany]: "Créer un compte entreprise",
    [tokens.page.register.title.collaborator]: "Vous êtes",
    [tokens.page.register.title.company]: "Votre organisation",
    [tokens.page.register.form.gender]: "Genre",
    [tokens.page.register.form.genderPlaceholder]: "Genre",
    [tokens.page.register.form.lastname]: "Nom",
    [tokens.page.register.form.firstname]: "Prénom",
    [tokens.page.register.form.jobTitle]: "Métier",
    [tokens.page.register.form.email]: "Email",
    [tokens.page.register.form.confirmEmail]: "Confirmer l'email",
    [tokens.page.register.form.password]: "Mot de passe",
    [tokens.page.register.form.confirmPassword]: "Confirmer le mot de passe",
    [tokens.page.register.form.phone]: "Téléphone",
    [tokens.page.register.form.name]: "Nom",
    [tokens.page.register.form.siretNumber]: "Numéro de siret",
    [tokens.page.register.form.activities]: "Secteur d'activité",
    [tokens.page.register.form.activitiesPlaceholder]: "Secteur d'activité",
    [tokens.page.register.form.category]: "Catégorie",
    [tokens.page.register.form.categoryPlaceholder]: "Catégorie",
    [tokens.page.register.form.address]: "Adresse",
    [tokens.page.register.form.additionalAddress]: "Complément d'adresse",
    [tokens.page.register.form.city]: "Ville",
    [tokens.page.register.form.postCode]: "Code postal",
    [tokens.page.register.form.phoneCompany]: "Téléphone",
    [tokens.page.register.form.submit]: "S'inscrire",
    [tokens.page.register.form.requiredFields]: "Veuillez remplir les champs obligatoires",
    [tokens.page.register.form.emailError]: "Les deux emails ne correspondent pas",
    [tokens.page.register.form.passwordError]: "Les deux mots de passe ne correspondent pas",
    [tokens.page.register.modal.title]: "Sélectionnez des activités",
    [tokens.page.offerDetails.presentationInternShip]: "A propos de ce stage",
    [tokens.page.offerDetails.presentationWork]: "A propos de cette alternance",
    [tokens.page.offerDetails.mission]: "Missions",
    [tokens.page.offerDetails.profile]: "Profil recherché",
    [tokens.page.offerDetails.deadlines]: "Échéances",
    [tokens.page.offerDetails.progress.singular]: "Reste {{remainingDays}} jour pour postuler",
    [tokens.page.offerDetails.progress.plural]: "Reste {{remainingDays}} jours pour postuler",
    [tokens.page.offerDetails.availableAt]: "Postuler avant le",
    [tokens.page.offerDetails.createdAt]: "Offre publié le",
    [tokens.page.offerDetails.typeOffer]: "Type d'offre",
    [tokens.page.offerDetails.internship]: "Stage",
    [tokens.page.offerDetails.workStudy]: "Alternance",
    [tokens.page.offerDetails.payed]: "Gratification",
    [tokens.page.offerDetails.isPayed]: "Obligatoire",
    [tokens.page.offerDetails.isNotPayed]: "Non obligatoire",
    [tokens.page.offerDetails.submitted]: "Candidatures déposées",
    [tokens.page.offerDetails.profileJob]: "Profils métiers",
    [tokens.page.offerDetails.skills]: "Compétences recherchées",
    [tokens.page.offerDetails.more]: "En savoir plus sur {{company}}",
    [tokens.page.offerDetails.cta]: "Postuler",
    [tokens.page.offerDetails.images.alt]: "Image de {{company}}",
    [tokens.page.offerDetails.similar.workStudy.title]: "Offres d'alternance similaires",
    [tokens.page.offerDetails.similar.internship.title]: "Offres de stage similaires",
    [tokens.page.offerDetails.similar.cta]: "Voir toutes les offres",
    [tokens.form.company.submit]: "Mettre à jour la fiche",
    [tokens.form.company.notifications.success]: "La fiche entreprise a bien été mise à jour",
    [tokens.page.admin.offers.apiCollectionTable.foundLabel]: "offre(s) trouvée(s)",
    [tokens.page.admin.offers.apiCollectionTable.itemsPerPageLabel]: "Offre(s) par page",
    [tokens.page.admin.offers.status.active]: "Active",
    [tokens.page.admin.offers.status.inactive]: "Clôturé",
    [tokens.page.admin.offers.type.workStudy]: "Alternance",
    [tokens.page.admin.offers.type.internship]: "Stage",
    [tokens.breadCrumb.home]: "Accueil",
    [tokens.breadCrumb.login]: "Connexion",
    [tokens.breadCrumb.uiExample]: "Exemples d'UI",
    [tokens.breadCrumb.companies]: "Entreprises",
    [tokens.breadCrumb.offers.base]: "Offres",
    [tokens.breadCrumb.offers.internship]: "Stages",
    [tokens.breadCrumb.offers.workStudy]: "Alternances",
    [tokens.breadCrumb.apply]: "Postuler",
    [tokens.sortings.clarifications.alphabeticalSortAZ]: "A-Z",
    [tokens.sortings.clarifications.alphabeticalSortZA]: "Z-A",
    [tokens.sortings.clarifications.dateASC]: "plus récent",
    [tokens.sortings.clarifications.dateDESC]: "moins récent",
    [tokens.card.company.internship.singular]: "stage",
    [tokens.card.company.internship.plural]: "stages",
    [tokens.card.company.workStudy.singular]: "alternance",
    [tokens.card.company.workStudy.plural]: "alternances",
    [tokens.card.offer.internship]: "Stage",
    [tokens.card.offer.workStudy]: "Alternance",
    [tokens.card.offer.dates.column]: "{{start}} au {{end}}",
    [tokens.card.offer.dates.row]: "Du {{start}} au {{end}} ({{duration}} jours)",
    [tokens.card.offer.payed]: "Rémunéré",
    [tokens.card.offer.cta.button.main]: "Postuler",
    [tokens.card.offer.cta.button.more]: "En savoir plus",
    [tokens.card.offer.cta.button.back]: "Retour",
    [tokens.card.offer.cta.progress.singular]: "Reste {{remainingDays}} jour pour postuler",
    [tokens.card.offer.cta.progress.plural]: "Reste {{remainingDays}} jours pour postuler",
    [tokens.card.request.studentLine]: "{{firstName}} ({{age}} ans)",
    [tokens.card.request.internship]: "Stage",
    [tokens.card.request.workStudy]: "Alternance",
    [tokens.card.request.dates]: "Du {{start}} au {{end}} ({{duration}} jours)",
    [tokens.page.apply.duration]: "Du {{start}} au {{end}}",
    [tokens.page.apply.title]: "Postuler à cette ofrre de stage",
    [tokens.page.apply.myPhoto.label]: "Votre photo",
    [tokens.page.apply.myPhoto.placeholder]: "<multiLine><main>Importer (500x500 pixels max)</main><grey>JPG or PNG (5 Mo max)</grey></multiLine>",
    [tokens.page.apply.mySkills]: "Vos compétences",
    [tokens.page.apply.youAre]: "Vous êtes",
    [tokens.page.apply.gender]: "Genre",
    [tokens.page.apply.firstname]: "Prénom",
    [tokens.page.apply.lastname]: "Nom",
    [tokens.page.apply.birth]: "Date de naissance",
    [tokens.page.apply.phone]: "Téléphone mobile",
    [tokens.page.apply.email]: "Email",
    [tokens.page.apply.confirmEmail]: "Confirmez votre email",
    [tokens.page.apply.address]: "Adresse",
    [tokens.page.apply.addressPlus]: "Complément d'adresse",
    [tokens.page.apply.postalCode]: "Code postal",
    [tokens.page.apply.city]: "Ville",
    [tokens.page.apply.personalWebsite]: "Adresse site web personnel",
    [tokens.page.apply.linkedIn]: "Liens vers votre profil LinkedIn",
    [tokens.page.apply.driverLicence]: "J'ai le permis de conduire",
    [tokens.page.apply.disability]: "J'ai une forme de handicap",
    [tokens.page.apply.mySituation]: "Votre situation actuelle",
    [tokens.page.apply.study]: "Niveau d'études",
    [tokens.page.apply.currentDiploma]: "Diplome préparé",
    [tokens.page.apply.school]: "Etablissement",
    [tokens.page.apply.currentFormation]: "Nom de la formation préparée",
    [tokens.page.apply.coverLetter]: "Vos atouts et motivations pour postuler à cette offre de stage",
    [tokens.page.apply.createAccount]: "Créer mon compte membre pour éviter de ressaisir ces informations à la fois prochaine",
    [tokens.page.apply.submit]: "Postuler à cette offre de stage",
    [tokens.page.apply.legal]: "En validant ce formulaire, vous confimez que vous acceptez nos conditions d'utilisation et notre politique de confidentialité",
    [tokens.page.apply.photoDescription]: "Ajouter votre photo à votre profil est apprécié par les entreprises et augmente vos chances",
    [tokens.page.apply.skillsDescription]: "Ajouter jusqu'a 10 compétences :",
    [tokens.page.apply.myLanguages]: "Vos langues :",
    [tokens.page.apply.languageDescription]: "Ajouter les langues que vous pratiquez :",
    [tokens.page.apply.documents]: "CV et autres documents",
    [tokens.page.apply.documentsDescription]: "Importez votre CV et votre lettre de motivation ou ajoutez tout document utile à votre candidature (présentation détaillée de vos projets, portfolio, etc.)",
    [tokens.page.apply.experiences]: "Vos expériences professionnelles",
    [tokens.page.apply.experiencesDescription]: "Stages, emplois d'été, projets personnels :",
    [tokens.page.apply.cvField.label]: "Votre CV",
    [tokens.page.apply.cvField.placeholder]: "Importer votre CV",
    [tokens.page.apply.cvRequirements]: "format PDF, 20 Mo max",
    [tokens.page.apply.coverLetterField.label]: "Lettre de motivation",
    [tokens.page.apply.coverLetterField.placeholder]: "Importer votre Lettre de motivation",
    [tokens.page.apply.coverLetterRequirements]: "format PDF, 20 Mo max",
    [tokens.page.apply.otherField.label]: "Autre document",
    [tokens.page.apply.otherField.placeholder]: "Importer un autre document",
    [tokens.page.apply.otherRequirements]: "format PDF ou ZIP, 50 Mo max",
    [tokens.page.apply.motivations]: "Vos atouts & motivations pour postuler à cette offre de stage",
    [tokens.page.apply.add.skill]: "Ajouter une compétence",
    [tokens.page.apply.add.language]: "Ajouter une langue",
    [tokens.page.apply.add.experience]: "Ajouter une expérience",
    [tokens.page.apply.addSkill]: "Sélectionner une compétence",
    [tokens.page.apply.notStudent]: "Seul un étudiant peut postuler à une offre",
    [tokens.page.apply.notifications.success]: "Votre demande à bien été envoyée !",
    [tokens.page.apply.notifications.error]: "Une erreur est survenue lors de l'envoi de votre demande",
    [tokens.sexes.man]: "Homme",
    [tokens.sexes.woman]: "Femme",
    [tokens.sexes.nonBinary]: "Non-binaire",
    [tokens.page.apply.errors.emailsNotMatch]: "Les emails ne correspondent pas",
    [tokens.admin.company.actions.addOffer]: "Nouvelle offre",
    [tokens.notifications.login]: "Connecté en tant que {{name}}",
    [tokens.notifications.logout]: "Vous avez bien été déconnecté",
    [tokens.notifications.accountUpdated]: "Compte mis à jour",
    [tokens.notifications.linkCopied]: "Le lien a bien été copié",
    [tokens.page.createOffer.title]: "Publier une offre",
    [tokens.page.createOffer.states[1].name]: "Type d'offre",
    [tokens.page.createOffer.states[2].name]: "Description",
    [tokens.page.createOffer.states[3].name]: "Publication",
    [tokens.page.createOffer.step]: "Étape",
    [tokens.page.createOffer.name.title]: "Nom de l'offre",
    [tokens.page.createOffer.name.description]: "Décrivez le nom de l’offre de stage ou d’alternance",
    [tokens.page.createOffer.name.placeholder]: "ex: Assistant marketing",
    [tokens.page.createOffer.name.formInfo]: "Au moins 50 caractères",
    [tokens.page.createOffer.type.title]: "Type d'offre",
    [tokens.page.createOffer.type.internship]: "Stage",
    [tokens.page.createOffer.type.workStudy]: "Alternance",
    [tokens.page.createOffer.remuneration.title]: "Rémunération",
    [tokens.page.createOffer.remuneration.placeholder]: "Montant par mois",
    [tokens.page.createOffer.remuneration.formInfo]: "575 € par mois minimum pour les stages de plus de 2 mois",
    [tokens.page.createOffer.searchSkills.title]: "Profils métiers",
    [tokens.page.createOffer.searchSkills.description]: "Ajouter les métiers recherchés",
    [tokens.page.createOffer.skills.title]: "Compétences",
    [tokens.page.createOffer.skills.description]: "Ajouter les compétences recherchés",
    [tokens.page.createOffer.add.skill]: "Ajouter une compétence",
    [tokens.page.createOffer.add.activity]: "Ajoutez un profil",
    [tokens.page.createOffer.nextStep]: "Etape suivant",
    [tokens.page.createOffer.previousStep]: "Etape précedente",
    [tokens.page.createOffer.submit]: "Valider",
    [tokens.page.createOffer.description.title]: "A propos",
    [tokens.page.createOffer.description.description]: "Texte d’introduction qui résume l’offre",
    [tokens.page.createOffer.description.placeholder]: "Sassiser votre texte ici",
    [tokens.page.createOffer.missions.title]: "Mission",
    [tokens.page.createOffer.missions.description]: "Décriver les tâches, missions et responsabilités qui seront confiées au candidat",
    [tokens.page.createOffer.missions.placeholder]: "Sassiser votre texte ici",
    [tokens.page.createOffer.profils.title]: "Profil recherché",
    [tokens.page.createOffer.profils.description]: "Décriver les qualités du candidat idéal en termes de savoir-faire et de savoir-être.",
    [tokens.page.createOffer.profils.placeholder]: "Sassiser votre texte ici",
    [tokens.page.createOffer.start.title]: "Date de début",
    [tokens.page.createOffer.end.title]: "Date de fin",
    [tokens.page.createOffer.availableAt.title]: "Postulé avant le",
    [tokens.page.createOffer.success]: "L'offre a bien été créé",
    [tokens.page.createStudent.submit]: "Je créer mon compte",
    [tokens.page.createStudent.password]: "Mot de passe",
    [tokens.page.createStudent.confirmPassword]: "Confirmer mot de passe",
    [tokens.page.createStudent.wrongPassword]: "Les mots de passe ne correspondent pas",
    [tokens.page.createStudent.study.placeholder]: "Selectionnez un niveau d'etudes",
    [tokens.page.confirmation.text]: "Votre compte a bien été crée",
    [tokens.page.confirmation.btn]: "Je me connecte",
    [tokens.errors[403].message]: "Vous ne devriez pas être là...",
    [tokens.errors[404].message]: "Il n'y a pas de stages ici !",
    [tokens.errors.actions.back]: "Retour au site",
}

export default fr
